import React from 'react';
import Notify from 'react-notification-alert';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { checkUpdates } from '../../helpers/actions/updates';
import { setInterval } from 'timers';

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: []
    };
    this.notifier = null;
    this.timer = null;
  }

  componentDidMount() {
    this.loadNotifications(this.props.notifications);
    this.timer = setInterval(() => this.updateNotifications(), 60000); // 1 min 60000
  }

  componentWillReceiveProps({ notifications }) {
    this.loadNotifications(notifications);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  updateNotifications() {
    this.props.dispatch(checkUpdates());
  }

  loadNotifications(notifications) {
    if (notifications.length) {
      notifications.forEach(n => {
        this.notifier.notificationAlert({
          place: 'tr',
          autoDismiss: 4,
          ...n
        });
      });
      this.props.dispatch({ type: 'CLEAN_NOTIFICATIONS' });
    }
  }

  render() {
    return <Notify ref={node => (this.notifier = node)} />;
  }
}

const mapStateToProps = state => {
  return { user: state.fb.auth, notifications: state.notifications };
};

export default compose(connect(mapStateToProps))(Notifications);
