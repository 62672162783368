import React from 'react';
import { ButtonGroup } from 'reactstrap';
import { Button } from '../../components';

class Rank extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: null,
      rank: null
    };
  }

  componentWillMount() {
    let { items, rank } = this.props;
    this.setState({ items, rank });
  }

  componentWillReceiveProps({ items, rank }) {
    this.setState({ items, rank });
  }

  generateRank1() {
    let { items, rank } = this.state;
    let buttons = [];

    for (let i = 1; i <= items; i++) {
      buttons.push(
        <Button
          key={`button-${i}`}
          round
          color={i <= rank ? 'info' : ''}
          onClick={() => this.props.setRank(i)}
        >
          <i
            className={`${
              i <= rank ? 'fas fa-star' : 'far fa-star'
            }  icon-action`}
          />
        </Button>
      );
    }

    return buttons;
  }

  render() {
    let { items } = this.state;
    if (items) {
      let buttons = this.generateRank1();

      return <ButtonGroup>{buttons}</ButtonGroup>;
    }
    return null;
  }
}

export default Rank;
