import React, { Component } from 'react';
import { connect } from 'react-redux';
import { all } from '../../helpers/actions/operation';
import { Row, Col } from 'reactstrap';
import FormInputs from '../../components/FormInputs/FormInputs';

import Pagination from '../Pagination/SimplePagination';

export default connect()(
  class extends Component {
    state = {
      data: [],
      loading: false,
      total: 0,
      page: 1,
      maxPerPage: 8,
      search: null,
      selected: null
    };

    load() {
      let { page, maxPerPage, search } = this.state,
        { dispatch } = this.props;

      const offset =
        page * maxPerPage - maxPerPage > 0 ? page * maxPerPage - maxPerPage : 0;

      this.setState({ loading: true });

      dispatch(all({ limit: maxPerPage, offset, filters: search || '' })).then(
        ({ meta, data }) =>
          this.setState({
            data,
            ...meta,
            loading: false
          })
      );
    }

    componentDidMount() {
      this.load();
    }

    onSelectRow(pro) {
      let { onSelect } = this.props;

      if (typeof onSelect === 'function') {
        onSelect(pro);
      }
    }

    render() {
      let {
        data,
        page,
        maxPerPage,
        total,
        count,
        search = '',
        selected
      } = this.state;

      return (
        <div>
          <Row>
            <Col>
              <FormInputs
                ncols={['col-lg-10 ml-auto mr-auto']}
                proprieties={[
                  {
                    inputProps: {
                      type: 'search',
                      placeholder: 'Search...',
                      value: search || '',
                      onChange: e =>
                        this.setState({ search: e.target.value }, this.load)
                    }
                  }
                ]}
              />
            </Col>
          </Row>

          <Row>
            {data.map((d, index) => (
              <Col sm={12} md={6} lg={3}>
                {d === selected ? (
                  <div className="img-circle float-right">
                    {' '}
                    <i
                      style={{ fontSize: '2.1em' }}
                      className="now-ui-icons ui-1_check"
                    />{' '}
                  </div>
                ) : null}

                <h6>{d.name}</h6>
                <p>{d.description}</p>
              </Col>
            ))}
          </Row>

          <Row>
            <Col>
              <Pagination
                page={page}
                maxPerPage={maxPerPage}
                total={total}
                count={count}
                onPageChange={p => this.setState({ page: p }, this.load)}
              />
            </Col>
          </Row>
        </div>
      );
    }
  }
);
