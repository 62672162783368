export const modal_container = {
  minWidth: '300px',
  maxWidth: '500px',
  width: 'auto',
  height: 'auto',
  minHeight: '50px',
  background: 'white',
  color: 'black',
  padding: '20px',
  borderRadius: '5px',
  boxShadow: '0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)',
  position: 'absolute',
  zIndex: 9999,
  top: '50px',
  left: 0,
  right: 0,
  margin: 'auto',
  textAlign: 'center'
};

const buttons = {
  padding: '10px 20px',
  color: 'white',
  fontSize: '14px',
  background: 'blue',
  border: 'none',
  cursor: 'pointer',
  margin: '0 10px',
  fontWeight: 'bold',
  borderRadius: '5px'
};

export const button_accept = {
  ...buttons
};

export const button_close = {
  ...buttons,
  background: 'black'
};

export default {
  modal_container,
  button_accept,
  button_close
};
