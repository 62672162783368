import React from 'react';
import styles from './styles';

class PWAInstallModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      installButton: true,
      updateButton: false
    };
    this.installPrompt = null;
  }

  componentDidMount() {
    this.listenEvents();
  }

  listenEvents = () => {
    // Listening for Updates in Services Worker
    if ('serviceWorker' in navigator) {
      let reg = navigator.serviceWorker;
      // Wait to ready serviceWorker
      reg.ready.then(rw => {
        // Function to detect any change
        rw.onupdatefound = () => {
          // Update service worker and send message to the user for reload page
          rw.update()
            .then(rwu => {
              this.reloadPage();
            })
            .catch(err => {
              alert('Error Service Worker Update');
            });
        };
      });

      if (
        window.matchMedia &&
        window.styleMedia &&
        window.styleMedia.type !== 'standalone'
      ) {
        // display modal to show how install de app through the browser
      }
    }

    // never execute events until the component is displayed
    if (!this.props.show) return;
    // Listening for Install prompt Updated
    window.addEventListener('beforeinstallprompt', e => {
      console.log('============ beforeinstallprompt ============');
      // For older browsers
      e.preventDefault();
      // Install Prompt fired
      this.installPrompt = e;
      // See if the app is already installed, in that case, do nothing
      if (
        (window.matchMedia &&
          window.styleMedia &&
          window.styleMedia.type === 'standalone') ||
        window.navigator.standalone === true
      ) {
        return false;
      } else {
        // Set the state variable to make modal to install visible
        this.setState({
          installButton: true
        });
      }
    });
  };

  installApp = async () => {
    if (!this.installPrompt) return false;
    this.installPrompt.prompt();

    let outcome = await this.installPrompt.userChoice;
    if (outcome.outcome === 'accepted') {
      console.log('App Installed');
    } else {
      console.log('App not installed');
    }
    // Remove the event reference
    this.installPrompt = null;
    // Hide the button
    this.setState({
      installButton: false
    });
  };

  close = () => {
    this.setState({
      installButton: false,
      updateButton: false
    });
  };

  reloadPage = () => {
    window.location.reload(true);
    this.setState({
      installButton: false,
      updateButton: false
    });
  };

  render() {
    if (this.props.show && this.state.updateButton) {
      return (
        <div style={{ ...styles.modal_container }}>
          <h4>
            {this.props.reloadMessage || 'New updates on the APP please reload'}
          </h4>
          <button style={{ ...styles.button_accept }} onClick={this.reloadPage}>
            {this.props.reload || 'Reload'}
          </button>
          <button style={{ ...styles.button_close }} onClick={this.close}>
            {this.props.close || 'Close'}
          </button>
        </div>
      );
    }

    if (this.props.show && this.state.installButton && this.installPrompt) {
      return (
        <div style={{ ...styles.modal_container }}>
          <h4>
            {this.props.message ||
              'You can install our APP clicking on the button below'}
          </h4>
          <button style={{ ...styles.button_accept }} onClick={this.installApp}>
            {this.props.accept || 'Install'}
          </button>
          <button style={{ ...styles.button_close }} onClick={this.close}>
            {this.props.close || 'Close'}
          </button>
        </div>
      );
    }

    return <div id={'pwa-not-ready'} hidden />;
  }
}

export default PWAInstallModal;
