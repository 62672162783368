import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import range from 'lodash/range';
import PropTypes from '../../../node_modules/prop-types';

class PaginationCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first: 1,
      previous: null,
      page: 1,
      next: null,
      last: 1,
      previousCount: 0,
      nextCount: 0
    };
  }

  componentWillMount() {
    let { page, count, maxPerPage } = this.props;

    this.getPaginationData(page, count, maxPerPage);
  }

  componentWillReceiveProps({ page, count, maxPerPage }) {
    this.getPaginationData(page, count, maxPerPage);
  }

  getPaginationData(page, count, maxPerPage) {
    let last = Math.ceil(count / maxPerPage);
    let previous = page > 1 ? page - 1 : null;
    let next = last > 1 && page < last ? page + 1 : null;
    let previousCount = 0;
    let nextCount = 0;

    let pagesPre = page - 1;
    let pagesNext = last - page;

    if (pagesPre >= 2 && pagesNext >= 2) {
      previousCount = 2;
      nextCount = 2;
    } else if (pagesPre < 2) {
      let needed = 4 - pagesPre;
      previousCount = pagesPre;
      nextCount = pagesNext >= needed ? needed : pagesNext;
    } else if (pagesNext < 2) {
      let needed = 4 - pagesNext;
      previousCount = pagesPre >= needed ? needed : pagesPre;
      nextCount = pagesNext;
    }

    this.setState({ previous, page, next, last, previousCount, nextCount });
  }

  render() {
    let {
        first,
        previous,
        page,
        next,
        last,
        previousCount,
        nextCount
      } = this.state,
      { className } = this.props;

    return (
      <Pagination className={className}>
        <PaginationItem>
          <PaginationLink
            disabled={first === page}
            onClick={() => this.props.onPageChange(first)}
          >
            <i className="fa fa-angle-double-left fa-2x" />
          </PaginationLink>
        </PaginationItem>

        <PaginationItem>
          <PaginationLink
            disabled={previous === null}
            onClick={() => this.props.onPageChange(previous)}
          >
            <i className="fa fa-angle-left fa-2x" />
          </PaginationLink>
        </PaginationItem>
        {previousCount > 0
          ? range(page - previousCount, page).map(pageNumber => {
              return (
                <PaginationItem key={pageNumber}>
                  <PaginationLink
                    onClick={() => this.props.onPageChange(pageNumber)}
                  >
                    {pageNumber}
                  </PaginationLink>
                </PaginationItem>
              );
            })
          : null}
        <PaginationItem active>
          <PaginationLink>{`${page} / ${last}`}</PaginationLink>
        </PaginationItem>
        {nextCount > 0
          ? range(page + 1, nextCount + page + 1).map(pageNumber => {
              return (
                <PaginationItem key={pageNumber}>
                  <PaginationLink
                    onClick={() => this.props.onPageChange(pageNumber)}
                  >
                    {pageNumber}
                  </PaginationLink>
                </PaginationItem>
              );
            })
          : null}
        <PaginationItem>
          <PaginationLink
            disabled={next === null}
            onClick={() => this.props.onPageChange(next)}
          >
            <i className="fa fa-angle-right fa-2x" />
          </PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink
            disabled={last === page}
            onClick={() => this.props.onPageChange(last)}
          >
            <i className="fa fa-angle-double-right fa-2x" />
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    );
  }
}

PaginationCustom.propTypes = {
  page: PropTypes.number,
  count: PropTypes.number,
  maxPerPage: PropTypes.number
};

export default PaginationCustom;
