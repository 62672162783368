import React from 'react'
import defaultImage from '../../assets/img/image_placeholder.jpg'

const style = {
    borderTop: '1px solid #f4f4f4',
    padding: '3px 0'
}

export default ({ items = [] }) => (
    <div>
        {items.map((it, i) => (
            <div key={i} className="row flex-row m-2" style={style}>
                <div className="img-container col-sm-2">
                    <img className="img-circle" src={it.image || defaultImage} alt="..." />
                </div>
                <div className="col-sm-8">
                    {it.text}
                </div>
                <div className="col-sm-2">
                    {it.actionComponent ? <it.actionComponent /> : null}
                </div>
            </div>
        ))}
    </div>
)